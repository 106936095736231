import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row, Stack} from 'react-bootstrap';
import {clone} from "../../../../util/objectUtil";
import {Platform} from "../../../../type/data/Platform";
import {ModalFormProps} from "../../../../type/component/FormProps";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import {
	loadDataSourceTypeCategoryOptions,
	loadDataSourceTypeContactOptions,
	loadDataSourceTypeCouponOptions,
	loadDataSourceTypeCouponTemplateOptions,
	loadDataSourceTypeInfoOptions,
	loadDataSourceTypeOrderOptions,
	loadDataSourceTypeProductOptions
} from "../../../../util/restUtil";

const LABEL_COL = 3;
const VALUE_COL = 9;

export type PlatformFormProps = ModalFormProps<Platform> & WithOnAlertProp & {}

function PlatformForm({data, onSave, onDelete, onClose, onAlert}: PlatformFormProps) {
	const [formData, setFormData] = useState<Platform>(clone(data));
	const [isValid, setIsValid] = useState<boolean>(true);
	const [contactFormats, setContactFormats] = useState<string[] | null>(null);
	const [categoryFormats, setCategoryFormats] = useState<string[] | null>(null);
	const [productFormats, setProductFormats] = useState<string[] | null>(null);
	const [orderFormats, setOrderFormats] = useState<string[] | null>(null);
	const [couponFormats, setCouponFormats] = useState<string[] | null>(null);
	const [couponTemplateFormats, setCouponTemplateFormats] = useState<string[] | null>(null);
	const [infoFormats, setInfoFormats] = useState<string[] | null>(null);

	const errorAlert = useCallback(
		(err: any) => onAlert({type: 'danger', title: 'Error', message: String(err)}),
		[onAlert]
	);

	const loadOptions = () => {
		loadDataSourceTypeContactOptions()
			.then(
				setContactFormats,
				errorAlert
			);
		loadDataSourceTypeCategoryOptions()
			.then(
				setCategoryFormats,
				errorAlert
			);
		loadDataSourceTypeProductOptions()
			.then(
				setProductFormats,
				errorAlert
			);
		loadDataSourceTypeOrderOptions()
			.then(
				setOrderFormats,
				errorAlert
			);
		loadDataSourceTypeCouponOptions()
			.then(
				setCouponFormats,
				errorAlert
			);
		loadDataSourceTypeCouponTemplateOptions()
			.then(
				setCouponTemplateFormats,
				errorAlert
			);
		loadDataSourceTypeInfoOptions()
			.then(
				setInfoFormats,
				errorAlert
			);
	}

	useEffect(loadOptions, []);

	const validate = () => {
		let valid = true;
		if (formData.name === undefined || formData.name === '') {
			valid = false;
		}
		setIsValid(valid);
	};

	useEffect(validate, [formData]);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	const save = () => {
		onSave(formData);
	}

	return (
		<Modal show={true} onHide={onClose} size="xl" centered>
			<Modal.Header closeButton>
				<Modal.Title>Platform - {formData.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={save}>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL}>ID</Form.Label>
						<Col sm={VALUE_COL}>
							<Form.Control
								plaintext
								readOnly
								value={String(formData.id || '')}>
							</Form.Control>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="name">Name</Form.Label>
						<Col sm={VALUE_COL}>
							<Form.Control
								id="name"
								type="text"
								required
								isInvalid={formData.name === undefined || formData.name === ''}
								value={formData.name}
								onChange={(e) => updateField(() => formData.name = e.target.value)}
							/>
							<Form.Control.Feedback type="invalid">
								Please fill in name of the platform.
							</Form.Control.Feedback>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="description">Description</Form.Label>
						<Col sm={VALUE_COL}>
							<Form.Control
								id="description"
								as="textarea"
								rows={2}
								value={formData.description}
								onChange={(e) => updateField(() => formData.description = e.target.value)}
							></Form.Control>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="importsSupported">Imports Supported</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="importsSupported"
								type="switch"
								checked={formData.importsSupported}
								onChange={(e) => updateField(() => formData.importsSupported = e.target.checked)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL}></Form.Label>
						<Col>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeInfo"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Info
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeInfo"
										value={formData.dataSourceTypeInfo}
										onChange={(e) => updateField(() => formData.dataSourceTypeInfo = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											infoFormats ?
												<>
													{infoFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeContact"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Contacts
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeContact"
										value={formData.dataSourceTypeContact}
										onChange={(e) => updateField(() => formData.dataSourceTypeContact = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											contactFormats ?
												<>
													{contactFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeCategory"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Categories
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCategory"
										value={formData.dataSourceTypeCategory}
										onChange={(e) => updateField(() => formData.dataSourceTypeCategory = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											categoryFormats ?
												<>
													{categoryFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeProduct"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Products
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeProduct"
										value={formData.dataSourceTypeProduct}
										onChange={(e) => updateField(() => formData.dataSourceTypeProduct = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											productFormats ?
												<>
													{productFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL}></Col>
								<Col sm={VALUE_COL}>
									<Stack direction="horizontal">
										<Form.Label
											column
											for="productFullUpdateRequired"
											className={formData.importsSupported ? '' : 'text-muted'}
										>
											Full Update Required
										</Form.Label>
										<Form.Check
											id="productFullUpdateRequired"
											type="switch"
											checked={formData.productFullUpdateRequired}
											onChange={(e) => updateField(() => formData.productFullUpdateRequired = e.target.checked)}
											disabled={!formData.importsSupported}
										/>
									</Stack>
									<div className="text-small text-muted">Always run full product import.</div>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL}></Col>
								<Col sm={VALUE_COL}>
									<Stack direction="horizontal">
										<Form.Label
											column
											for="manualProductDeactivationRequired"
											className={formData.importsSupported ? '' : 'text-muted'}
										>
											Deactivation Required
										</Form.Label>
										<Form.Check
											id="manualProductDeactivationRequired"
											type="switch"
											checked={formData.manualProductDeactivationRequired}
											onChange={(e) => updateField(() => formData.manualProductDeactivationRequired = e.target.checked)}
											disabled={!formData.importsSupported}
										/>
									</Stack>
									<div className="text-small text-muted">Load full list of active products from feed and deactivate all other.</div>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL}></Col>
								<Col sm={VALUE_COL}>
									<Stack direction="horizontal">
										<Form.Label
											column
											for="productInvalidationRequired"
											className={formData.importsSupported ? '' : 'text-muted'}
										>
											Invalidation Required
										</Form.Label>
										<Form.Check
											id="productInvalidationRequired"
											type="switch"
											checked={formData.productInvalidationRequired}
											onChange={(e) => updateField(() => formData.productInvalidationRequired = e.target.checked)}
											disabled={!formData.importsSupported}
										/>
									</Stack>
									<div className="text-small text-muted">Deactivate all products having value of <i>validTo</i> in the past.</div>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeOrder"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Orders
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeOrder"
										value={formData.dataSourceTypeOrder}
										onChange={(e) => updateField(() => formData.dataSourceTypeOrder = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											orderFormats ?
												<>
													{orderFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeCouponTemplate"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Coupon Templates
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCouponTemplate"
										value={formData.dataSourceTypeCouponTemplate}
										onChange={(e) => updateField(() => formData.dataSourceTypeCouponTemplate = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											couponTemplateFormats ?
												<>
													{couponTemplateFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Form.Label
									column
									sm={LABEL_COL}
									for="dataSourceTypeCoupon"
									className={formData.importsSupported ? '' : 'text-muted'}
								>
									Coupons
								</Form.Label>
								<Col>
									<Form.Select
										id="dataSourceTypeCoupon"
										value={formData.dataSourceTypeCoupon}
										onChange={(e) => updateField(() => formData.dataSourceTypeCoupon = e.target.value)}
										disabled={!formData.importsSupported}
									>
										{
											couponFormats ?
												<>
													{couponFormats.map((f) => <option key={f} value={f}>{f}</option>)}
												</> : <option>loading...</option>
										}
									</Form.Select>
								</Col>
							</Row>
							<Row>
								<Col sm={LABEL_COL}></Col>
								<Col sm={VALUE_COL}>
									<Stack direction="horizontal">
										<Form.Label
											column
											for="couponCreateSupported"
											className={formData.importsSupported ? '' : 'text-muted'}
										>
											Coupon Create Supported
										</Form.Label>
										<Form.Check
											id="couponCreateSupported"
											type="switch"
											checked={formData.couponCreateSupported}
											onChange={(e) => updateField(() => formData.couponCreateSupported = e.target.checked)}
											disabled={!formData.importsSupported}
										/>
									</Stack>
								</Col>
							</Row>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="eventsSupported">Events Supported</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="eventsSupported"
								type="switch"
								checked={formData.eventsSupported}
								onChange={(e) => updateField(() => formData.eventsSupported = e.target.checked)}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm={LABEL_COL} for="trackingSupported">Tracking Supported</Form.Label>
						<Col sm={VALUE_COL} className="d-flex align-items-center">
							<Form.Check
								id="trackingSupported"
								type="switch"
								checked={formData.trackingSupported}
								onChange={(e) => updateField(() => formData.trackingSupported = e.target.checked)}
							/>
						</Col>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="link" onClick={onClose}>Close</Button>
				<Button variant="danger" disabled={formData.id === null || onDelete === undefined}
					onClick={() => window.confirm(`Really delete platform ${formData.name}?`) && onDelete !== undefined && onDelete(formData)}>Delete</Button>
				<Button variant="success" type="submit" onClick={save} disabled={!isValid}>Save</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default PlatformForm;
