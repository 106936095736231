import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Stack} from 'react-bootstrap';
import {clone} from "../../../../util/objectUtil";
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import {FeedBrowserFilter} from "../../../../type/data/FeedBrowserFilter";
import TextInputWithReset from "../../../controls/TextInputWithReset";
import {importStashOptionsList} from "../../../../util/restUtil";
import PluginSearchModal from "../PluginSearchModal";
import {Plugin} from "../../../../type/data/Plugin";
import {BsArrowRepeat} from 'react-icons/bs';

export type FeedBrowserFilterBarProps = WithOnAlertProp & {
	filter: FeedBrowserFilter;
	onSubmit: (f: FeedBrowserFilter) => any;
}

const addEmptyOption = (list: string[]) => {
	list.unshift('');
	return list;
}

function FeedBrowserFilterBar({filter, onSubmit, onAlert}: FeedBrowserFilterBarProps) {
	const [formData, setFormData] = useState<FeedBrowserFilter>(clone(filter));
	const [isValid, setIsValid] = useState<boolean>(true);
	const [formats, setFormats] = useState<string[] | null>(null);
	const [entities, setEntities] = useState<string[] | null>(null);
	const [workers, setWorkers] = useState<string[] | null>(null);
	const [showSearchForm, setShowSearchForm] = useState<boolean>(false);

	const errorAlert = useCallback(
		(err: any) => onAlert({type: 'danger', title: 'Error', message: String(err)}),
		[onAlert]
	);

	const loadEntities = () => {
		importStashOptionsList("entity")
			.then((list) => {
				setEntities(addEmptyOption(list));
			})
			.catch(e => errorAlert(e))
	}

	useEffect(loadEntities, []);

	const loadWorkers = () => {
		importStashOptionsList("worker")
			.then((list) => {
				setWorkers(addEmptyOption(list));
			})
			.catch(e => errorAlert(e))
	}

	useEffect(loadWorkers, []);

	const loadFormats = () => {
		importStashOptionsList("format")
			.then((list) => {
				setFormats(addEmptyOption(list));
			})
			.catch(e => errorAlert(e))
	}

	useEffect(loadFormats, []);

	const validate = () => {
		let valid = true;
		setIsValid(valid);
	};

	useEffect(validate, [formData]);

	const updateField = (action: () => any) => {
		action();
		setFormData(clone(formData));
	}

	const save = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			onSubmit(formData)
		},
		[onSubmit, formData]
	);

	const pluginSearchConfirmed = useCallback(
		(plugin: Plugin) => {
			setShowSearchForm(false);
			updateField(() => formData.pluginId = String(plugin.id));
			onSubmit(formData);
		},
		[]
	);

	return (
		<div className="filter-bar py-2">
			<Form onSubmit={save}>
				<Stack direction="horizontal" className="d-flex gap-2 justify-content-between">
					<Form.Label for="plugin">Plugin:</Form.Label>
					<TextInputWithReset
						id="plugin"
						value={formData.pluginId}
						onChange={(v) => updateField(() => formData.pluginId = v)}
					/>
					<Button onClick={() => setShowSearchForm(true)}>
						...
					</Button>

					<Form.Label for="entity">Entity:</Form.Label>
					<Form.Select
						id="entity"
						value={String(formData.entity)}
						onChange={(e) => updateField(() => formData.entity = e.target.value)}
					>
						{
							entities ?
								<>
									{entities.map((f) => <option key={f} value={f}>{f}</option>)}
								</> : <option>loading...</option>
						}
					</Form.Select>

					<Form.Label for="worker">Worker:</Form.Label>
					<Form.Select
						id="worker"
						value={String(formData.worker)}
						onChange={(e) => updateField(() => formData.worker = e.target.value)}
					>
						{
							workers ?
								<>
									{workers.map((f) => <option key={f} value={f}>{f}</option>)}
								</> : <option>loading...</option>
						}
					</Form.Select>

					<Form.Label for="format">Format:</Form.Label>
					<Form.Select
						id="format"
						value={String(formData.format)}
						onChange={(e) => updateField(() => formData.format = e.target.value)}
					>
						{
							formats ?
								<>
									{formats.map((f) => <option key={f} value={f}>{f}</option>)}
								</> : <option>loading...</option>
						}
					</Form.Select>

					<Form.Label for="search">Search:</Form.Label>
					<TextInputWithReset
						id="search"
						value={formData.search}
						onChange={(v) => updateField(() => formData.search = v)}
					/>

					<Button type="submit" disabled={!isValid}>
						<BsArrowRepeat/>
					</Button>
				</Stack>
			</Form>
			{
				showSearchForm ?
					<PluginSearchModal
						onAlert={errorAlert}
						onCancelled={() => setShowSearchForm(false)}
						onConfirmed={pluginSearchConfirmed}/>
					: <></>
			}
		</div>
	);
}

export default FeedBrowserFilterBar;
